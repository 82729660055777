/*
* Slack投稿用アイコン管理
* depts
  id: 1, name: 営業
  id: 2, name: マーケティング
  id: 3, name: その他
  id: 9, name: 営業戦略
  id: 15, name: イツザイ事業本部
  id: 21, name; 情報システム 

  prefectures
  id: 13, name: 東京都
  id: 23, name: 愛知県
  id: 27, name: 大阪府
  id: 40, name: 福岡県
  id: 48, name: 指定なし
*/
const slackIcons = {
  depts1_office1: 'tokyo_sales.png',
  depts2_office1: 'tokyo_market.png',
  depts9_office1: 'tokyo_strategy.png',
  depts15_office1: 'tokyo_itzai.png',
  depts21_office1: 'tokyo_system.png',
  depts1_office3: 'nagoya_sales.png',
  depts2_office3: 'nagoya_market.png',
  depts9_office3: 'nagoya_strategy.png',
  depts15_office3: 'nagoya_itzai.png',
  depts21_office3: 'nagoya_system.png',
  depts1_office2: 'osaka_sales.png',
  depts2_office2: 'osaka_market.png',
  depts9_office2: 'osaka_strategy.png',
  depts15_office2: 'osaka_itzai.png',
  depts21_office2: 'osaka_system.png',
  depts1_office4: 'fukuoka_sales.png',
  depts2_office4: 'fukuoka_market.png',
  depts9_office4: 'fukuoka_strategy.png',
  depts15_office4: 'fukuoka_itzai.png',
  depts21_office4: 'fukuoka_system.png',
  depts1_office6: 'yokohama_sales.png',
  depts2_office6: 'yokohama_market.png',
  depts9_office6: 'yokohama_strategy.png',
  depts15_office6: 'yokohama_itzai.png',
  depts21_office6: 'yokohama_system.png',
  depts1_office9: 'sapporo_sales.png',
  depts2_office9: 'sapporo_market.png',
  depts9_office9: 'sapporo_strategy.png',
  depts15_office9: 'sapporo_itzai.png',
  depts21_office9: 'sapporo_system.png',
  depts1_office10: 'sendai_sales.png',
  depts2_office10: 'sendai_market.png',
  depts9_office10: 'sendai_strategy.png',
  depts15_office10: 'sendai_itzai.png',
  depts21_office10: 'sendai_system.png',
  depts1_office11: 'hirosima_sales.png',
  depts2_office11: 'hirosima_market.png',
  depts9_office11: 'hirosima_strategy.png',
  depts15_office11: 'hirosima_itzai.png',
  depts21_office11: 'hirosima_system.png',
  depts3: 'other.png',
}

export default class {
  constructor(param) {
  
    this.path = '/images/slack_icons/'
    this.img = ''

    if(param.depts_id && param.office_id) {
      let depts_id = param.depts_id
      if (depts_id == 24 || depts_id == 26 || depts_id == 41) {
        depts_id = 1  // 営業企画課もしくは営業兼マーケもしくは営業企画推進部所属の場合は営業扱いでアイコン作成
      }
      const office_id = param.office_id == 5 ? 1 : param.office_id  // 秋葉原を東京扱いに
      const id = `depts${depts_id}_office${office_id}`
      this.img = slackIcons[id]
    } else {
      this.img = slackIcons.depts3
    }
   
  }
 
  get() {
    return this.path + this.img
  }
}