class ChannelDatas {

    constructor() {

        // 通常カレンダー
        this.channelIDList = {
            "アポ獲得-東京": ["C0896MB2L5U", "C08AD56NM1N"],
            "アポ獲得-秋葉原": ["C08A280J464", "C089SRZJ5KN"],
            "アポ獲得-横浜": ["C089S59EMMF", "C089SS001L4"],
            "アポ獲得-大阪": ["C089DD6C6QK", "C0899QEA1FH"],
            "アポ獲得-名古屋": ["C08A2AP760G", "C089QAHRB50"],
            "アポ獲得-福岡": ["C089G2BDREY", "C089SRZ6HD2"],
            "アポ獲得-札幌": ["C089DHNRQ8K", "C0899QG7QDV"],
            "アポ獲得-仙台": ["C089SBM5XDX", "C089QAKQUHG"],
            "アポ獲得-広島": ["C089DLX73R8", "C089HMX58G6"],
            "アポ獲得（自営）-東京": ["C088YQ5CH5M", "C089MFFQEQ5"],
            "アポ獲得（自営）-秋葉原": ["C089FV1QL2G", "C089MFHBTS9"],
            "アポ獲得（自営）-横浜": ["C089DCEFSTV", "C089SRZTNJ0"],
            "アポ獲得（自営）-大阪": ["C0896T5KNSJ", "C08AD572NBS"],
            "アポ獲得（自営）-名古屋": ["C088Z0K1X3R", "C089Q822B6F"],
            "アポ獲得（自営）-福岡": ["C0896TW94FQ", "C0899QF1VGX"],
            "アポ獲得（自営）-札幌": ["C088Z4RKCA3", "C089MFJ497F"],
            "アポ獲得（自営）-仙台": ["C089G6L090C", "C0899QGJM7Z"],
            "アポ獲得（自営）-広島": ["C089SC6FYHF", "C089HMX3806"],
            "アポ獲得-当アポ-東京": ["C08A24RQ3SL", "C089Q81CNCT"],
            "アポ獲得-当アポ-秋葉原": ["C089FUV71RA", "C08AD58BSSU"],
            "アポ獲得-当アポ-横浜": ["C089DETVD34", "C08AD58P8G0"],
            "アポ獲得-当アポ-大阪": ["C088Z066FL7", "C0899QEBXM5"],
            "アポ獲得-当アポ-名古屋": ["C08A2AQFTME", "C089QAHT79Q"],
            "アポ獲得-当アポ-福岡": ["C089AMB3FKP", "C08AD580080"],
            "アポ獲得-当アポ-札幌": ["C089AR67BMK", "C08A31Q0281"],
            "アポ獲得-当アポ-仙台": ["C08A2F7K4SC", "C089SS0SLGL"],
            "アポ獲得-当アポ-広島": ["C089G70LJCU", "C089QALA7HQ"],
            "アポ獲得（自営）-当アポ-東京": ["C08A24RQ3SL", "C089Q81CNCT"],
            "アポ獲得（自営）-当アポ-秋葉原": ["C089FUV71RA", "C08AD58BSSU"],
            "アポ獲得（自営）-当アポ-横浜": ["C089DETVD34", "C08AD58P8G0"],
            "アポ獲得（自営）-当アポ-大阪": ["C088Z066FL7", "C0899QEBXM5"],
            "アポ獲得（自営）-当アポ-名古屋": ["C08A2AQFTME", "C089QAHT79Q"],
            "アポ獲得（自営）-当アポ-福岡": ["C089AMB3FKP", "C08AD580080"],
            "アポ獲得（自営）-当アポ-札幌": ["C089AR67BMK", "C08A31Q0281"],
            "アポ獲得（自営）-当アポ-仙台": ["C08A2F7K4SC", "C089SS0SLGL"],
            "アポ獲得（自営）-当アポ-広島": ["C089G70LJCU", "C089QALA7HQ"],
            "リスキャン-東京": ["C089S1H2ZAM", "C0899QE4AMV"],
            "リスキャン-秋葉原": ["C0896R4J8PQ", "C089HMVMANS"],
            "リスキャン-横浜": ["C089G0ZFBA8", "C089Q83EEG3"],
            "リスキャン-大阪": ["C088Z0A1H63", "C089SRYJYTE"],
            "リスキャン-名古屋": ["C089DG4HDJN", "C089MFGRSFP"],
            "リスキャン-福岡": ["C088Z10TX4P", "C089QAJCVPC"],
            "リスキャン-札幌": ["C089DHVDP4K", "C089HMWFJCE"],
            "リスキャン-仙台": ["C089DJ4UY67", "C08A31QJG6M"],
            "リスキャン-広島": ["C088Z5L6CCX", "C0899QHAV0X"],
            "リスキャン-当アポ-東京": ["C08A256500G", "C08A31MNKA5"],
            "リスキャン-当アポ-秋葉原": ["C089DDUT926", "C08AD58DH40"],
            "リスキャン-当アポ-横浜": ["C088YUX9095", "C08A31PLT25"],
            "リスキャン-当アポ-大阪": ["C089DDDGV3M", "C0899QEE6B1"],
            "リスキャン-当アポ-名古屋": ["C089S7B0HFB", "C089SRYUDB6"],
            "リスキャン-当アポ-福岡": ["C0896U1J7QW", "C089HMV868N"],
            "リスキャン-当アポ-札幌": ["C089G6E05V2", "C089HMWCR70"],
            "リスキャン-当アポ-仙台": ["C089ARMACSZ", "C08A31QGASV"],
            "リスキャン-当アポ-広島": ["C08A2FZ7ARE", "C089SS1BA9J"],
            "再訪設定-東京": ["C088YQFC0S3", "C0899QE62SK"],
            "再訪設定-秋葉原": ["C089S52AP33", "C08D508URT8"],
            "再訪設定-横浜": ["C08A29SEEP2", "C08DBG1RLFM"],
            "再訪設定-大阪": ["C08A2AM116C", "C08A31N8LGH"],
            "再訪設定-名古屋": ["C089AM6QFLM", "C089Q82BQG3"],
            "再訪設定-福岡": ["C089S7V6H6V", "C089MFH9U3F"],
            "再訪設定-札幌": ["C089SBKL1ND", "C08CX3477EK"],
            "再訪設定-仙台": ["C089G6T69C4", "C089Q84CBMH"],
            "再訪設定-広島": ["C089SCGAW2D", "C08DQ9PSJLR"],
        }

        this.officeMapping = {
            1: "東京",
            2: "大阪",
            3: "名古屋",
            4: "福岡",
            5: "秋葉原",
            6: "横浜",
            // 7,8は外部とベトナム
            9: "札幌",
            10: "仙台",
            11: "広島"
        }

    }

    // 通常カレンダーの登録にあるものかどうかをチェック
    getChannelID (groupTitle, officeID, isToday = false) {
        const officeName = this.officeMapping[officeID]
        const todayAp = isToday ? "当アポ-" : ""
        const channelName = groupTitle + "-" + todayAp + officeName
        const channelIDs = this.channelIDList[channelName]
        return channelIDs
    }

}

export default ChannelDatas